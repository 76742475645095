import React from 'react';
import parseHydraDocumentation from '@api-platform/api-doc-parser/lib/hydra/parseHydraDocumentation';
import { HydraAdmin, hydraClient, fetchHydra as baseFetchHydra } from '@api-platform/admin';
import authProvider from './authProvider';
import { Redirect } from 'react-router-dom';
import RichTextInput from 'ra-input-rich-text';

const entrypoint = process.env.REACT_APP_API_ENDPONT + '/api'; // Change this by your own entrypoint
const fetchHeaders = {
    'Authorization': `Bearer ${window.localStorage.getItem('token')}`,
    'Is-Admin': '1',
};
const fetchHydra = (url, options = {}) => baseFetchHydra(url, {
    ...options,
    headers: new Headers(fetchHeaders),
});

const dataProvider = api => hydraClient(api, fetchHydra);
const apiDocumentationParser = entrypoint => parseHydraDocumentation(entrypoint, { headers: new Headers(fetchHeaders) })
    .then(
        ({ api }) => {

            const pages = api.resources.find(({ name }) => 'pages' === name);
            const content = pages.fields.find(f => 'content' === f.name);

            content.input = props => (
              <RichTextInput {...props} source="content" />
            );

            api.resources = api.resources.filter(function(value) {
                return value.name === 'pages' || value.name === 'users';
            });
            return { api };
        },
        (result) => {
            switch (result.status) {
                case 401:
                    return Promise.resolve({
                        api: result.api,
                        customRoutes: [{
                            props: {
                                path: '/',
                                render: () => <Redirect to={`/login`}/>,
                            },
                        }],
                    });
                default:
                    return Promise.reject(result);
            }
        },
    );

export default props => (
    <HydraAdmin
        apiDocumentationParser={apiDocumentationParser}
        authProvider={authProvider}
        entrypoint={entrypoint}
        dataProvider={dataProvider}
    />
);
